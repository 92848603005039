export const ctaImageBlock = `
_type == 'ctaImageBlock' => {
  image {
    ...,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    image_mobile {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  link {
    ...,
    internalLink->{
      _type,
      "uid": uid[_key == $lang][0].value,
      context,
    },
  },
  "title": title[_key == $lang][0].value,
  "accroche": accroche[_key == $lang][0].value,
  "btn_label": btn_label[_key == $lang][0].value,
}
`
