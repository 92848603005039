import { portableTextWithInternalLinks } from '../utils/portableText'

export const subscribeBlock = `
_type == 'subscribeBlock' => {
  "subscribe_to": subscribe_to[_key == $lang][0].value,
  "available_at": available_at[_key == $lang][0].value,
  "explanations": explanations[_key == $lang][0].value[] {
    ${portableTextWithInternalLinks}
  },
  image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
}
`
