export const fromSanityTypesToPath = {
  blog: 'blogs',
  article: 'blogs/news',
  care_guide: 'blogs/guides-dentretien',
  product: 'products',
  page: 'pages',
  collection: 'collections',
}

export type SupportedSanityTypeForPath = keyof typeof fromSanityTypesToPath

export const fromSanityLanguageKeyToLocale = {
  fr: 'fr-fr',
  en: 'en-us',
  de: 'de-de',
}
