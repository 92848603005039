export const mosaicBlock = `
_type == 'mosaicBlock' => {
  image_1 {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    image_mobile {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  image_2 {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    image_mobile {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  image_3 {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    image_mobile {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
}
`
