import { Campaign, Campaigns, Flags } from './types/abtasty'

export type VisitorInput = {
  visitorId: string
  context: {
    [key: string]: any
  }
  visitorConsent: boolean
  triggerHit: boolean
  decisionGroup: string | null
}

const toABTastyInput = (input: VisitorInput): Record<string, any> => {
  return {
    visitor_id: input.visitorId,
    context: input.context,
    visitor_consent: input.visitorConsent,
    trigger_hit: input.triggerHit,
    decision_group: input.decisionGroup,
  }
}

export type ActivateInput = {
  visitorId: string
  variationId: string
  variationGroupId: string
}

export type PageviewHitInput = {
  // Environment ID
  cid: string
  // Visitor ID
  vid: string
  // Data Source
  ds: string
  // Type
  t: string
  // Document Location URL
  dl: string
  // Page title
  pt?: string
}

export type EventHitInput = PageviewHitInput & {
  // Event Category
  ec: string
  // Event Action
  ea: string
  // Event Label
  el?: string
  // Event Value
  ev?: number
}

const toABTastyActivateInput = (input: ActivateInput): Record<string, any> => {
  return {
    vid: input.visitorId,
    cid: process.env.ABTASTY_ENVIRONNEMENT_ID,
    vaid: input.variationId,
    caid: input.variationGroupId,
  }
}

class ABTastyService {
  private headers: any
  private baseUrl: string

  constructor() {
    if (!process.env.ABTASTY_ENVIRONNEMENT_ID) {
      throw new Error('Missing env var ABTASTY_ENVIRONNEMENT_ID')
    }
    if (!process.env.ABTASTY_API_KEY) {
      throw new Error('Missing env var ABTASTY_API_KEY')
    }

    this.baseUrl = `https://decision.flagship.io/v2`
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.ABTASTY_API_KEY}`,
    }
  }

  async campaigns(input: VisitorInput): Promise<Campaigns> {
    try {
      const response = await fetch(
        `${this.baseUrl}/${process.env.ABTASTY_ENVIRONNEMENT_ID}/campaigns`,
        {
          method: 'POST',
          body: JSON.stringify(toABTastyInput(input)),
          headers: { ...this.headers },
        }
      )

      if (!response.ok) {
        throw new Error(
          `unexpected error while fetching visitor campaigns with : ${response.statusText}`
        )
      }

      const data: Campaigns = await response.json()

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async campaign(id: string, input: VisitorInput): Promise<Campaign> {
    try {
      const response = await fetch(
        `${this.baseUrl}/${process.env.ABTASTY_ENVIRONNEMENT_ID}/campaigns/${id}`,
        {
          method: 'POST',
          body: JSON.stringify(toABTastyInput(input)),
          headers: { ...this.headers },
        }
      )

      if (!response.ok) {
        throw new Error(
          `unexpected error while fetching visitor campaign with : ${response.statusText}`
        )
      }

      const data: Campaign = await response.json()

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async flags(input: VisitorInput): Promise<Flags> {
    try {
      const response = await fetch(
        `${this.baseUrl}/${process.env.ABTASTY_ENVIRONNEMENT_ID}/flags`,
        {
          method: 'POST',
          body: JSON.stringify(toABTastyInput(input)),
          headers: { ...this.headers },
        }
      )

      if (!response.ok) {
        throw new Error(
          `unexpected error while fetching visitor flags with : ${response.statusText}`
        )
      }

      const data: Flags = await response.json()

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async activate(input: ActivateInput): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/activate`, {
        method: 'POST',
        body: JSON.stringify(toABTastyActivateInput(input)),
        headers: { ...this.headers },
      })

      if (!response.ok) {
        throw new Error(
          `unexpected error while activating visitor with : ${response.statusText}`
        )
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async track(input: PageviewHitInput | EventHitInput): Promise<void> {
    // EVENT
    //   curl -X POST \
    // https://events.flagship.io \
    // -H 'content-type: application/json' \
    // -d '{
    // "cid": "1234567890azertyuiop",
    // "dl": "https://react-ecommerce-demo.internal.flagship.io/",
    // "ea": "newsletterSubscription",
    // "ec": "Action Tracking",
    // "el": "optin",
    // "ev": 1,
    // "t": "EVENT",
    // "vid": "18100217380532936",
    // "ds": "APP"
    // }'

    // PAGEVIEW
    // "cid": "{{environmentId}}",
    // "dl": "https://www.abtasty.com",
    // "t": "PAGEVIEW",
    // "vid": "{{visitorId}}",
    // "ds":"APP"
    try {
      const response = await fetch('https://events.flagship.io', {
        method: 'POST',
        body: JSON.stringify(input),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new Error(
          `unexpected error while pushing event with : ${response.statusText}`
        )
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ABTastyService()
