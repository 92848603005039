import { portableTextWithInternalLinks } from '../utils/portableText'

export const columnBlock = `
_type == 'columnBlock' => {
  columns[]{
    ...,
    "text": text[_key == $lang][0].value[] {
      ${portableTextWithInternalLinks}
    },
  }
}
`
