import { Campaign, Campaigns, SliderCampaign } from '~/services/types/abtasty'

export default class ABTestCampaigns {
  // struct passée par AB Tasty listant les campagnes
  public campaigns: Campaign[] = []

  // short list
  public sliderCampaigns: Map<string, SliderCampaign> = new Map()

  public static fromCampaigns(data: Campaigns): ABTestCampaigns {
    const res = new ABTestCampaigns()

    res.campaigns = data.campaigns

    const sliderCampaigns: Campaign[] = data.campaigns.filter(
      (c: Campaign) =>
        c.variation.modifications.type === 'FLAG' &&
        c.variation.modifications.value.product_slider
    )

    for (const sliderCampaign of sliderCampaigns) {
      res.sliderCampaigns.set(
        sliderCampaign.variation.modifications.value.product_slider?.product,
        {
          id: sliderCampaign.id,
          variationGroupId: sliderCampaign.variationGroupId,
          variation: sliderCampaign.variation,
          product:
            sliderCampaign.variation.modifications.value.product_slider
              ?.product,
          color:
            sliderCampaign.variation.modifications.value.product_slider?.color,
          isOriginal: sliderCampaign.variation.name === 'Original',
          alternateSliderId:
            sliderCampaign.variation.modifications.value.product_slider
              ?.alternate_slider_id,
          alternateConfigId:
            sliderCampaign.variation.modifications.value.product_slider
              ?.alternate_config_id,
        }
      )
    }

    return res
  }

  public hasProductSliderTest(productUid: string): boolean {
    return this.sliderCampaigns.has(productUid)
  }

  public getProductSliderTest(productUid: string): SliderCampaign | null {
    const res = this.sliderCampaigns.get(productUid)
    return res === undefined ? null : res
  }

  public toJSON() {
    return Object.assign({}, this)
  }
  //   public isSubscribed(productSlug: string) {
  //     return (
  //       this.subscriptions.has(productSlug) &&
  //       this.subscriptions.get(productSlug) === true
  //     )
  //   }

  //   public subscribe(productSlug: string) {
  //     this.subscriptions.set(productSlug, true)
  //   }

  //   public unsubscribe(productSlug: string) {
  //     this.subscriptions.set(productSlug, false)
  //   }
}
