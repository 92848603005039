import groq from 'groq'
import { blocks } from '../blocks'

export const getPagesGroq = groq`
*[_type == "page" && $lang in uid[]._key] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "alternate_languages": uid[_key != $lang],
    "title": title[_key == $lang][0].value,
    image {
        crop,
        image-> {
            _type,
            _id,
            path,
            dimensions
        }
    },
    ${blocks}
}`

export const getPageGroq = groq`
*[_type == "page" 
    && $uid in uid[].value
    && $lang in uid[]._key
] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "alternate_languages": uid[_key != $lang],
    "title": title[_key == $lang][0].value,
    image {
        crop,
        image-> {
            _type,
            _id,
            path,
            dimensions
        }
    },
    ${blocks}
}`

export const getFormGroq = groq`
*[_type == "form" 
    && $uid in uid[].value
    && $lang in uid[]._key
] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "alternate_languages": uid[_key != $lang],
    "title": title[_key == $lang][0].value,
    "form_id": form_id[_key == $lang][0].value,
}`
