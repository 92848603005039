import axios from 'axios'

import { SHOPIFY_TYPES } from '../const'
import ShopifyBase64 from '../utils/shopifyBase64'
import { PRODUCTS_GRAPHQL } from './graphql/product'

class ProductService {
  private headers: Object
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }
    if (!process.env.SHOPIFY_API_VERSION) {
      throw new Error('Missing env var SHOPIFY_API_VERSION')
    }
    if (!process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
      throw new Error('Missing env var SHOPIFY_STOREFRONT_ACCESS_TOKEN')
    }

    this.baseUrl = `${process.env.SHOPIFY_URL}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`
    this.headers = {
      'X-Shopify-Storefront-Access-Token': `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }
  }

  getShopifyProduct(id: string, upselling?: boolean) {
    const encodedId = ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id)

    return axios.post(
      this.baseUrl,
      {
        query: `query product($id: ID!) {
          node(id: $id) {
            ... on Product {
              id
              title
              handle
              totalInventory
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              compareAtPriceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              productType
              tags
              vendor
              metafield(namespace: "product", key: "delivery") {
                value
              }
              metafields(identifiers: [{namespace: "prismic", key: "links"}, {namespace: "stock", key: "start_date_web"}, {namespace: "stock", key: "end_date"}]) {
                namespace
                key
                value
              }
              images(first: 1) {
                edges {
                  node {
                    ${
                      upselling
                        ? 'src: transformedSrc(crop: CENTER, maxWidth: 86, maxHeight: 86, scale: 2)'
                        : 'originalSrc'
                    }
                  }
                }
              }
              variants(first: 100) {
                edges {
                  node {
                    id
                    title
                    quantityAvailable
                    sku
                    barcode
                    price {
                      amount
                      currencyCode
                    }
                    compareAtPrice {
                      amount
                      currencyCode
                    }
                    availableForSale
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }`,
        variables: {
          id: encodedId,
        },
      },
      {
        headers: { ...this.headers },
      }
    )
  }

  refreshShopifyProduct(id: string, lang: string) {
    const encodedId = ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id)

    return axios.post(
      this.baseUrl,
      {
        query: `query product($id: ID!) {
          node(id: $id) {
            ... on Product {
              totalInventory
              metafield(namespace: "product", key: "delivery") {
                value
              }
              metafields(identifiers: [{namespace: "prismic", key: "links"}, {namespace: "stock", key: "start_date_web"}, {namespace: "stock", key: "end_date"}]) {
                namespace
                key
                value
              }
              variants(first: 100) {
                edges {
                  node {
                    id
                    quantityAvailable
                    price {
                      amount
                      currencyCode
                    }
                    compareAtPrice {
                      amount
                      currencyCode
                    }
                    availableForSale
                    sku
                  }
                }
              }
            }
          }
        }`,
        variables: {
          id: encodedId,
        },
      },
      {
        headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
      }
    )
  }

  getShopifyProducts(ids: string[], lang: string = process.env.DEFAULT_LOCALE) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `
            query products($ids: [ID!]!) {
              nodes(ids: $ids) {
                ... on Product {
                  ${PRODUCTS_GRAPHQL}
                }
              }
            }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return response.data.data.nodes
        }
      })
  }

  getShopifyProductsForCartItem(
    ids: string[],
    lang: string = process.env.DEFAULT_LOCALE
  ) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `
            query products($ids: [ID!]!) {
              nodes(ids: $ids) {
                ... on Product {
                  id
                  title
                  images(first: 1) {
                    edges {
                      node {
                        src: transformedSrc(crop: CENTER, maxWidth: 86, maxHeight: 86, scale: 2)
                      }
                    }
                  }
                  variants(first: 100) {
                    edges {
                      node {
                        id
                      }  
                    }
                  }
                  priceRange {
                    minVariantPrice {
                      amount
                    }
                  }
                  options(first: 100) {
                    name
                    values
                  }
                }
              }
            }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return response.data.data.nodes
        }
      })
  }

  refreshShopifyProducts(ids: string[], lang: string) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `
          query products($ids: [ID!]!) {
            nodes(ids: $ids) {
              ... on Product {
                id
                totalInventory
                metafield(namespace: "product", key: "delivery") {
                  value
                }
                variants(first: 100) {
                  edges {
                    node {
                      id
                      quantityAvailable
                      price {
                        amount
                        currencyCode
                      }
                      compareAtPrice {
                        amount
                        currencyCode
                      }
                      availableForSale
                      sku
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }
          return response.data.data.nodes
        }
      })
  }

  getShopifyProductsTranslatedContent(ids: string[], lang: string) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `
            query products($ids: [ID!]!) {
              nodes(ids: $ids) {
                ... on Product {
                  id
                  variants(first: 100) {
                    edges {
                      node {
                        id
                        selectedOptions {
                          name
                          value
                        }
                      }
                    }
                  }
                }
              }
            }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return [response.data.data.nodes, lang]
        }

        return [[], lang]
      })
  }

  getShopifyProductsMetafieldDelivery(ids: string[], lang: string) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `query products($ids: [ID!]!) {
            nodes(ids: $ids) {
            ... on Product {
              id
              metafield(namespace: "product", key: "delivery") {
                value
              }
            }
          }
        }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return response.data.data.nodes
        }
      })
  }

  getShopifyProductPriceAndMetafield(id: string, lang: string) {
    const encodedId: string = ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id)

    return axios
      .post(
        this.baseUrl,
        {
          query: `query product($id: ID!) {
            node(id: $id) {
              ... on Product {
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
                compareAtPriceRange {
                  minVariantPrice {
                    amount
                  }
                }
                metafield(namespace: "product", key: "delivery") {
                  value
                }
                metafields(identifiers: [{namespace: "stock", key: "start_date_web"}, {namespace: "stock", key: "end_date"}]) {
                  namespace
                  key
                  value
                }
              }
            }
          }`,
          variables: {
            id: encodedId,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return response.data.data.node
        }
      })
  }

  getShopifyProductsInventory(ids: string[]) {
    const encodedIds: string[] = []

    ids.forEach((id) => {
      if (id) {
        encodedIds.push(ShopifyBase64.encode(SHOPIFY_TYPES.PRODUCT, id))
      }
    })

    return axios
      .post(
        this.baseUrl,
        {
          query: `query products($ids: [ID!]!) {
            nodes(ids: $ids) {
            ... on Product {
              id
              totalInventory
            }
          }
        }`,
          variables: {
            ids: encodedIds,
          },
        },
        {
          headers: { ...this.headers },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return response.data.data.nodes
        }
      })
  }
}

export default new ProductService()
