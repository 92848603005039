import groq from 'groq'
import { blocks } from '../blocks'
import { productCardGroq, productPageGroq } from '../utils/product'

export const getHomeGroq = groq`
*[_type == "home" 
  && context == $context
  && $lang in uid[]._key 
] {
  _id,
  _type,
  context,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  ${blocks}
}`

export const getCollectionsGroq = groq`
*[_type == "collection" && $lang in uid[]._key] {
  _id,
  _type,
  context,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  ${blocks}
}`

export const getCollectionGroq = groq`
*[_type == "collection" 
  && context == $context
  && $uid in uid[].value 
  && $lang in uid[]._key
] {
  _id,
  _type,
  context,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  ${blocks}
}`

export const getProductsSoonSaleableGroq = groq`
*[
  _type == "product" 
  && status == "agenda"
  && dates.start > now() 
  && $lang in uid[]._key
  && context == $context
  && version != "variant"
] | order(dates.start desc) {
  ${productCardGroq}
}`

export const getProductsByShopifyIdsGroq = groq`
*[
  _type == "product"
  && shopify_product._ref in $ids
  && context == $context
  && $lang in uid[]._key
  && version != "variant"
]  {
  ${productCardGroq}
}`

export const getProductsUidByStatusAndContextGroq = groq`
*[
  _type == "product" 
  && status in $status
  && $lang in uid[]._key
  && version != "variant"
] {
  _id,
  "uid": uid[_key == $lang][0].value,
}
`

export const getProductsUidByShopifyIdsGroq = groq`
*[
  _type == "product" 
  && shopify_product._ref in $ids
  && $lang in uid[]._key
  && version != "variant"
] {
  _id,
  "uid": uid[_key == $lang][0].value,
  context,
}
`

export const getProductsByUidGroq = groq`
*[_type == "product" && count(uid[_key == $lang && value in $uids]) > 0] {
  ${productPageGroq},
  ${blocks}
}
`

export const getProductGroq = groq`
*[
  _type == "product"
  && $uid in uid[].value 
  && $lang in uid[]._key
] {
  ${productPageGroq},
  ${blocks}
}
`

export const getSliderByIdGroq = groq`
*[
  _id == $id
] {
  ...,
  elements[] {
    ...,
    _type == 'slider_image' => {
      image {
        crop,
        image-> {
          ...,
        }
      }
    }
  }
}`
