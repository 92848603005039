import Vue from 'vue'
import { ActionTree } from 'vuex'

import { RootState } from '../types'
import { ABTestingState } from './types'

import api from '@/services'
import { COOKIE_VISITOR_ID_KEY } from '@/const'
import { Campaigns, VariationInput } from '~/services/types/abtasty'
import { ActivateInput, VisitorInput } from '~/services/abtastyService'
import ABTestCampaigns from '~/entities/ABTestCampaigns'

export const actions: ActionTree<ABTestingState, RootState> = {
  getCampaigns({ commit }, visitorId: string): Promise<ABTestCampaigns> {
    return new Promise<ABTestCampaigns>((resolve, reject) => {
      const visitorInput: VisitorInput = {
        visitorId,
        context: {},
        triggerHit: false,
        visitorConsent: true,
        decisionGroup: null,
      }

      api.abtastyService
        .campaigns(visitorInput)
        .then((campaigns: Campaigns) => {
          const res = ABTestCampaigns.fromCampaigns(campaigns)
          commit('CAMPAIGNS', res)
          resolve(res)
        })
        .catch((err: any) => {
          commit('ERRORS', [err])
          reject(err)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    })
  },
  activate({ state, commit }, variation: VariationInput): Promise<void> {
    if (state.visitorId) {
      const activateInput: ActivateInput = {
        visitorId: state.visitorId,
        variationId: variation.variationId,
        variationGroupId: variation.variationGroupId,
      }

      return new Promise<void>((resolve, reject) => {
        commit('LOADING', true)
        api.abtastyService
          .activate(activateInput)
          .then(() => {
            // TODO: on met quelque chose à jour ?
            resolve()
          })
          .catch((err: any) => {
            commit('ERRORS', err)
            reject(err)
          })
          .finally(() => {
            commit('LOADING', false)
          })
      })
    }

    return Promise.reject(new Error('No valid campaign'))
  },
  hasVisitorId({ commit }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (Vue.prototype.$cookies.isKey(COOKIE_VISITOR_ID_KEY)) {
        const visitorId = Vue.prototype.$cookies.get(COOKIE_VISITOR_ID_KEY)

        if (visitorId) {
          // TEMP : c'est pour rendre le cookie dispo aux sous-domaines
          Vue.prototype.$cookies.set(COOKIE_VISITOR_ID_KEY, visitorId, {
            expires: '1y',
            domain: '.asphalte.com',
          })

          commit('VISITOR_ID', visitorId)
          resolve(true)
          return
        }

        Vue.prototype.$cookies.remove(COOKIE_VISITOR_ID_KEY)
      }
      commit('VISITOR_ID', '')
      resolve(false)
    })
  },
  setVisitorId({ dispatch, commit }, visitorId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      Vue.prototype.$cookies.set(COOKIE_VISITOR_ID_KEY, visitorId, {
        expires: '1y',
        domain: '.asphalte.com',
      })
      commit('VISITOR_ID', visitorId)
      dispatch('getCampaigns', visitorId)
      resolve()
    })
  },
}
