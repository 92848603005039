import { portableTextWithInternalLinks } from '../utils/portableText'

export const tracabiliteBlock = `
_type == 'tracabiliteBlock' => {
  parcours[]{
    ...,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    "title": title[_key == $lang][0].value,
    "impacts": impacts[_key == $lang][0].value[] {
      ${portableTextWithInternalLinks}
    },
    "factory_name": factory_name[_key == $lang][0].value,
    "factory_region": factory_region[_key == $lang][0].value,
  },
}
`
