import { portableTextWithInternalLinks } from '../utils/portableText'

export const morphoBlock = `
_type == 'morphoBlock' => {
  video_thumbnail {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
  list[] {
    ...,
    "text": text[_key == $lang][0].value[] {
      ${portableTextWithInternalLinks}
    },
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    zoom_1 {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    zoom_2 {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    zoom_3 {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    zoom_4 {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  }
}
`
