import { GetterTree } from 'vuex'

import { RootState } from '../types'
import { ABTestingState } from './types'
import ABTestCampaigns from '~/entities/ABTestCampaigns'

export const getters: GetterTree<ABTestingState, RootState> = {
  visitorId(state: ABTestingState): string | null {
    return state.visitorId
  },
  campaigns(state: ABTestingState): ABTestCampaigns {
    return state.campaigns
  },
  isLoading(state: ABTestingState): boolean {
    return state.loading
  },
  errors(state: ABTestingState): string[] {
    return state.errors
  },
}
