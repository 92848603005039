import axios from 'axios'
import PostalAddress from 'i18n-postal-address'

import { ErrorLogger } from '@/plugins/errorLogger'

class CustomerService {
  private headers: Object
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }
    if (!process.env.SHOPIFY_API_VERSION) {
      throw new Error('Missing env var SHOPIFY_API_VERSION')
    }
    if (!process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
      throw new Error('Missing env var SHOPIFY_STOREFRONT_ACCESS_TOKEN')
    }

    this.baseUrl = `${process.env.SHOPIFY_URL}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`
    this.headers = {
      'X-Shopify-Storefront-Access-Token': `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }
  }

  createCustomerAccessToken(email: String, password: String): Promise<any> {
    const input = {
      email: `${email}`,
      password: `${password}`,
    }

    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
          customerAccessTokenCreate(input: $input) {
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
          variables: {
            input,
          },
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.customerAccessTokenCreate
        ) {
          return response.data.data.customerAccessTokenCreate
        }

        const err = new Error(
          'CustomerServiceError: customerAccessTokenCreate response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'CustomerService',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  createCustomer(input: Object): Promise<any> {
    // let input = {
    //   email: email,
    //   password: hashedPassword,
    //   firstName :firstName,
    //   lastName : lastName,
    //   phone: phone,
    //   acceptsMarketing: acceptsMarketing,
    // }
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerCreate($input: CustomerCreateInput!) {
          customerCreate(input: $input) {
            customer {
              id
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
          variables: {
            input,
          },
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.customerCreate
        ) {
          return response.data.data.customerCreate
        }

        const err = new Error(
          'CustomerServiceError: CustomerCreate response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'CustomerService',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  getCustomer(accessToken: string): Promise<any> {
    return axios
      .post(
        this.baseUrl,
        {
          query: `query {
          customer(customerAccessToken: "${accessToken}") {
            id
            email
            displayName
            firstName
            lastName
            phone
            defaultAddress {
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              formatted
              formattedArea
              id
              lastName
              latitude
              longitude
              name
              phone
              province
              provinceCode
              zip
            }
            addresses(first: 100) {
              edges {
                node {
                  address1
                  address2
                  city
                  company
                  country
                  countryCodeV2
                  firstName
                  formatted
                  formattedArea
                  id
                  lastName
                  latitude
                  longitude
                  name
                  phone
                  province
                  provinceCode
                  zip
                }
              }
            }
            createdAt
            numberOfOrders
            metafields(identifiers: [
              {namespace: "gender-api", key: "gender-accuracy"}, 
              {namespace: "gender-api", key: "gender"}, 
              {namespace: "customer", key: "gender"}, 
              {namespace: "customer", key: "birthday"}
            ]) {
              id
              key
              namespace
              value
            }
          }
        }`,
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          return response.data.data.customer
        }

        const err = new Error(
          'CustomerServiceError: GetCustomer response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'GetCustomer',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  activateCustomer(
    id: String,
    activationToken: String,
    password: String
  ): Promise<any> {
    const input = {
      activationToken,
      password,
    }
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
          customerActivate(id: $id, input: $input) {
            customer {
              id
              email
              firstName
              lastName
              acceptsMarketing
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
          variables: {
            id,
            input,
          },
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.customerActivate
        ) {
          return response.data.data.customerActivate
        }

        const err = new Error(
          'CustomerServiceError: customerActivate response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'CustomerService',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  updateCustomer(customerAccessToken: String, customer: Object): Promise<any> {
    // let input = {
    // "customerAccessToken": "ae0f1d2e179c9571122a0595a6ac8125",
    // "customer": {
    // email: email,
    //   firstName :firstName,
    //   lastName : lastName,
    //   phone: phone,
    //   acceptsMarketing: acceptsMarketing,
    // }
    // }
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
          customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
            customer {
              id
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
          variables: {
            customerAccessToken,
            customer,
          },
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.customerUpdate
        ) {
          return response.data.data.customerUpdate
        }

        const err = new Error(
          'CustomerServiceError: CustomerUpdate response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'CustomerService',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  sendResetPassword(email: String) {
    return axios.post(
      this.baseUrl,
      {
        query: `mutation customerRecover($email: String!) {
          customerRecover(email: $email) {
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
        variables: {
          email,
        },
      },
      { headers: this.headers }
    )
  }

  updatePasswordByUrl(password: String, resetUrl: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
          customerResetByUrl(resetUrl: $resetUrl, password: $password) {
            customer {
              id
              email
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
          variables: {
            password,
            resetUrl,
          },
        },
        { headers: this.headers }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.customerResetByUrl
        ) {
          return response.data.data.customerResetByUrl
        }

        const err = new Error(
          'CustomerServiceError: customerResetByUrl response not valid'
        )

        ErrorLogger.error(err, {
          tags: {
            entity: 'CustomerService',
          },
          extra: {
            status: response.status,
            data: response.data.errors
              ? JSON.stringify(response.data.errors)
              : JSON.stringify(response.data),
          },
        })

        return Promise.reject(err)
      })
  }

  getCustomerOrders(
    accessToken: String,
    lang: String,
    orderFilterQuery?: String
  ) {
    let orderQuery = ''
    if (orderFilterQuery) {
      orderQuery = `, query: "${orderFilterQuery}"`
    }
    return axios
      .post(
        this.baseUrl,
        {
          query: `query {
          customer(customerAccessToken: "${accessToken}") {
            id
            orders(first: 100, reverse: true${orderQuery}) {
              edges {
                node {
                  id
                  name
                  orderNumber
                  financialStatus
                  processedAt
                  email
                  canceledAt
                  fulfillmentStatus
                  originalTotalPrice {
                    amount
                  }
                  shippingAddress {
                    id
                    firstName
                    lastName
                    address1
                    address2
                    city
                    company
                    zip
                    country
                    formattedArea
                  }
                  lineItems(first: 100) {
                    edges {
                      node {
                        title
                        quantity
                        currentQuantity
                        variant {
                          title
                          product {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          if (response.data.data.customer.orders.edges.length > 0) {
            return response.data.data.customer.orders.edges
          }
        }

        return []
      })
  }

  getCustomerOrder(accessToken: String, lang: String, orderNumber: string) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `query {
          customer(customerAccessToken: "${accessToken}") {
            id
            orders(first: 1, query: "id:${orderNumber}") {
              edges {
                node {
                  id
                  name
                  orderNumber
                  processedAt
                  financialStatus
                  email
                  phone
                  subtotalPrice { amount }
                  originalTotalDuties { amount }
                  originalTotalPrice { amount }
                  totalShippingPrice { amount }
                  totalTax { amount }
                  totalRefunded { amount}
                  originalTotalDuties { amount }
                  shippingDiscountAllocations { 
                    allocatedAmount { amount }
                    discountApplication {
                      allocationMethod
                      targetSelection
                      targetType
                      value
                    }
                  }
                  discountApplications(first: 100) {
                    edges {
                      node {
                        allocationMethod
                        targetSelection
                        targetType
                        value {
                          ... on MoneyV2 {
                            amount
                          }
                          ... on PricingPercentageValue {
                            percentage
                          }
                        }
                      }
                    }
                  }
                  shippingAddress {
                    id
                    name
                    firstName
                    lastName
                    address1
                    address2
                    city
                    company
                    zip
                    country
                    countryCodeV2
                    province
                    phone
                    formatted
                  }
                  billingAddress {
                    name
                    firstName
                    lastName
                    address1
                    address2
                    city
                    company
                    zip
                    country
                    countryCodeV2
                    province
                    phone
                    formatted
                  }
                  lineItems(first: 100) {
                    edges {
                      node {
                        title
                        quantity
                        currentQuantity
                        discountedTotalPrice { amount }
                        originalTotalPrice { amount }
                        discountAllocations { allocatedAmount { amount } }
                        variant {
                          id
                          sku
                          title
                          image {
                            id
                            src: transformedSrc(crop: CENTER, maxWidth: 110, maxHeight: 130, scale: 2)
                            altText
                          }
                          product {
                            title
                            metafield(key: "guide", namespace: "product") {
                              value
                            }
                          }
                        }
                        customAttributes {
                          key
                          value
                        }
                      }
                    }
                  }
                  successfulFulfillments(first: 100) {
                    trackingCompany
                    trackingInfo(first: 100) {
                      number
                      url
                    }
                    fulfillmentLineItems(first: 100) {
                      edges {
                        node {
                          lineItem {
                            title
                            variant {
                              id
                              product {
                                id
                              }
                            }
                          }
                          quantity
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          if (response.data.data.customer.orders.edges.length > 0) {
            return response.data.data.customer.orders.edges[0].node
          }
        }

        return null
      })
  }

  getCustomerOrdersByDates(accessToken: String, lang: String, cursor?: string) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `query {
          customer(customerAccessToken: "${accessToken}") {
            id
            orders(first: 50, reverse: true${
              cursor ? `, after: "${cursor}"` : ''
            }) {
              edges {
                node {
                  id
                  name
                  processedAt
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.errors && response.data.errors.length > 0) {
            return Promise.reject(response.data.errors[0].message)
          }

          if (response.data.data.customer) {
            return {
              orders: response.data.data.customer.orders.edges,
              totalCount: response.data.data.customer.orders.totalCount,
              pageInfo: response.data.data.customer.orders.pageInfo,
            }
          }
        }

        return {
          orders: [],
          totalCount: 0,
          pageInfo: { hasNextPage: false, endCursor: '' },
        }
      })
  }

  createAddress(
    accessToken: String,
    postalAddress: PostalAddress,
    phone: String
  ) {
    const o = postalAddress.toObject()

    const address = {
      address1: o.address1,
      address2: o.address2,
      zip: o.postalCode,
      city: o.city,
      company: o.companyName,
      country: o.country,
      firstName: o.firstName,
      lastName: o.lastName,
      phone,
    }

    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
            customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
              customerAddress {
                address1
                address2
                city
                company
                country
                countryCodeV2
                firstName
                formatted
                formattedArea
                id
                lastName
                latitude
                longitude
                name
                phone
                province
                provinceCode
                zip
              }
              customerUserErrors {
                code
                field
                message
              }
            }
          }`,
          variables: {
            address,
            customerAccessToken: accessToken,
          },
        },
        {
          headers: { ...this.headers },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          if (
            response.data.data.customerAddressCreate &&
            response.data.data.customerAddressCreate.customerUserErrors.length >
              0
          ) {
            throw response.data.data.customerAddressCreate.customerUserErrors
          }

          if (
            response.data.data.customerAddressCreate &&
            response.data.data.customerAddressCreate.customerAddress
          ) {
            return response.data.data.customerAddressCreate.customerAddress
          }
        }

        return null
      })
  }

  updateAddress(
    accessToken: String,
    id: String,
    postalAddress: PostalAddress,
    phone: String
  ) {
    const o = postalAddress.toObject()

    const address = {
      address1: o.address1,
      address2: o.address2,
      zip: o.postalCode,
      city: o.city,
      company: o.companyName,
      country: o.country,
      firstName: o.firstName,
      lastName: o.lastName,
      phone,
    }

    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
            customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
              customerAddress {
                address1
                address2
                city
                company
                country
                countryCodeV2
                firstName
                formatted
                formattedArea
                id
                lastName
                latitude
                longitude
                name
                phone
                province
                provinceCode
                zip
              }
              customerUserErrors {
                code
                field
                message
              }
            }
          }`,
          variables: {
            id,
            address,
            customerAccessToken: accessToken,
          },
        },
        {
          headers: { ...this.headers },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          if (
            response.data.data.customerAddressUpdate &&
            response.data.data.customerAddressUpdate.customerUserErrors.length >
              0
          ) {
            throw response.data.data.customerAddressUpdate.customerUserErrors
          }

          if (
            response.data.data.customerAddressUpdate &&
            response.data.data.customerAddressUpdate.customerAddress
          ) {
            return response.data.data.customerAddressUpdate.customerAddress
          }
        }

        return null
      })
  }

  setDefaultAddress(accessToken: String, id: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
            customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
              customer {
                id
                defaultAddress {
                  address1
                  address2
                  city
                  company
                  country
                  countryCodeV2
                  firstName
                  formatted
                  formattedArea
                  id
                  lastName
                  latitude
                  longitude
                  name
                  phone
                  province
                  provinceCode
                  zip
                }
              }
              customerUserErrors {
                code
                field
                message
              }
            }
          }`,
          variables: {
            addressId: id,
            customerAccessToken: accessToken,
          },
        },
        {
          headers: { ...this.headers },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          if (
            response.data.data.customerDefaultAddressUpdate &&
            response.data.data.customerDefaultAddressUpdate.customer
          ) {
            return response.data.data.customerDefaultAddressUpdate.customer
          }
        }

        return null
      })
  }

  deleteAddress(accessToken: String, id: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
            customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
              customerUserErrors {
                code
                field
                message
              }
              deletedCustomerAddressId
            }
          }`,
          variables: {
            id,
            customerAccessToken: accessToken,
          },
        },
        {
          headers: { ...this.headers },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          return response.data.data.customerAddressDelete
            ?.deletedCustomerAddressId
        }

        return null
      })
  }
}

export default new CustomerService()
