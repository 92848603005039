import { portableTextWithInternalLinks } from '../utils/portableText'

export const assuranceBlock = `
_type == 'assuranceBlock' => {
  "title": title[_key == $lang][0].value,
  elements[]{
    ...,
    "text": text[_key == $lang][0].value[] {
      ${portableTextWithInternalLinks}
    },
  },
}
`
