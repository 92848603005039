import { Context, Plugin } from '@nuxt/types'
import { MetaInfo } from 'vue-meta/types/vue-meta'
import { RouteRecord } from 'vue-router'

import Clarity from '@microsoft/clarity'

import Product, { IVariant } from '@/entities/shopify/Product'

import { CURRENCY, ORIGIN } from '@/const'
import ShopifyBase64 from '@/utils/shopifyBase64'
import Customer from '@/entities/Customer'

import loadGtm from '@/plugins/gtm'
import { Cart, CartLine } from '@/services/types/cart'

import abtastyService from '@/services/abtastyService'

const DATA_LAYER_NAME = 'dataLayer'

const ENV_ABTASTY = process.env.ABTASTY_ENVIRONNEMENT_ID

class Tracking {
  ctx: Context
  count = 0
  consent: any = {}
  visitorId = ''

  constructor(ctx: Context) {
    this.ctx = ctx

    if (ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    if (
      (process.env.BUILD_ENV === 'production' &&
        window.location.hostname.endsWith('asphalte.com')) ||
      process.env.LOAD_GTM === 'true'
    ) {
      this.installGTM()
    }

    if (process.client) {
      if (process.env.CLARITY_KEY) {
        Clarity.init(process.env.CLARITY_KEY)
        Clarity.consent(false)
        Clarity.setTag('site_version', process.env.SITE_VERSION || 'default')
      }

      if (window.Cookiebot) {
        this.updateConsent()
      }

      window.addEventListener('CookiebotOnConsentReady', this.updateConsent)
      window.addEventListener('CookieConsentDeclined', this.updateConsent)
      window.addEventListener('CookieConsentAccepted', this.updateConsent)
    }
  }

  getDataLayer() {
    return (
      window[DATA_LAYER_NAME] === null && (window[DATA_LAYER_NAME] = []),
      Array.isArray(window[DATA_LAYER_NAME]) ? window[DATA_LAYER_NAME] : []
    )
  }

  gtag(args: any) {
    this.getDataLayer().push(args)
  }

  installGTM() {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    loadGtm()
  }

  clearEcommerce() {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    this.gtag({ event: 'clear_ecommerce', ecommerce: null })
  }

  updateConsent() {
    if (process.client) {
      if (window.Cookiebot?.consent) {
        this.consent = window.Cookiebot.consent

        if (this.consent.statistics) {
          if (window.clarity) {
            window.clarity('consent')
          }
        } else if (window.clarity) {
          window.clarity('consent', false)
        }
      }
    }
  }

  async getUserData(customer?: Customer) {
    const data: any = {}

    this.updateConsent()

    if (
      (customer ||
        (this.ctx.store.state.auth && this.ctx.store.state.auth.customer)) &&
      this.consent.marketing
    ) {
      const user: Customer = customer || this.ctx.store.state.auth.customer
      const decodedId = ShopifyBase64.getId(user.id)
      const encodeUserId = await this.digestMessage(decodedId.toString())
      const encodedEmail = await this.digestMessage(user.email)
      const encodePhone = user.phone
        ? await this.digestMessage(user.phone)
        : undefined

      const userData: any = {}
      userData.customer_id = encodeUserId
      userData.email = encodedEmail
      userData.phone = encodePhone
      userData.first_name = user.firstName || undefined
      userData.last_name = user.lastName || undefined
      userData.country = user.defaultAddress
        ? user.defaultAddress.country
        : undefined
      userData.zip = user.defaultAddress ? user.defaultAddress.zip : undefined
      userData.city = user.defaultAddress ? user.defaultAddress.city : undefined

      data.user_data = userData
      data.leadsUserData = {
        sha256_email_address: encodedEmail,
      }
    }

    return data
  }

  event(name: string, options?: any) {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    this.gtag({
      event: name,
      version: process.env.SITE_VERSION,
      page_title: document.title,
      page_location: window.location.href,
      page_path: this.ctx.route.fullPath,
      ...options,
    })

    if (this.count >= 2 && this.count < 3) {
      if (
        typeof batchSDK !== 'undefined' &&
        typeof Notification !== 'undefined'
      ) {
        if (
          Notification.permission !== 'granted' &&
          Notification.permission !== 'denied'
        ) {
          batchSDK((api: any) => {
            api.ui.show('native')
          })
          this.count = 3
        }
      }
    }

    if (window.clarity) {
      window.clarity('event', name)
    }
  }

  setLocale(locale: string) {
    this.event('locale', {
      event_category: 'i18n',
      locale,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setUserLanguage(locale.substring(0, 2))
      })
    }
  }

  async digestMessage(message: string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)
    const hash = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hash)) // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('') // convert bytes to hex string
    return hashHex
  }

  async setUser(customer: Customer) {
    const decodedId = ShopifyBase64.getId(customer.id)
    const userData = await this.getUserData(customer)

    this.gtag({
      event: 'set_user',
      event_category: 'user',
      ...userData,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(decodedId)
      })
    }
  }

  context(context: string) {
    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.addTag('context', context)
        })
      })
    }
  }

  page(meta: MetaInfo, route: RouteRecord, referrer: RouteRecord) {
    this.count += 1
    this.event('page_view', {
      page_title: meta.title ? meta.title : document.title,
      page_location: window.location.href,
      page_path: route.path,
      page_referrer: referrer && referrer.path ? referrer.path : undefined,
    })

    // AB Tasty
    if (ENV_ABTASTY) {
      abtastyService.track({
        cid: ENV_ABTASTY,
        dl: window.location.href,
        pt: document.title,
        t: 'PAGEVIEW',
        vid: this.ctx.store.state.abtest.visitorId,
        ds: 'APP',
      })
    }
  }

  modal(name: string, additionnalOptions: object) {
    this.event('modal_view', {
      ...additionnalOptions,
      event_category: 'navigation',
      modal_title: name,
    })
  }

  generateProductCategories(product: Product) {
    const itemCategories: any = {}

    if (product.productType) {
      itemCategories.item_category = product.productType
    }

    product.tags.forEach((t, index) => {
      itemCategories[`item_category${index + 2}`] = t
    })

    return itemCategories
  }

  async product(name: string, product: Product, variants: IVariant[]) {
    const price = product.price

    this.clearEcommerce()

    const userDatas = await this.getUserData()

    this.event('view_item', {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Produit consulté',
      page_location: window.location.href,
      page_path: window.location.pathname,
      ecommerce: {
        currency: product.currency,
        value: price,
        product_name: name,
        product_id: product.id,
        product_category: product.productType,
        items: variants.map((variant) => ({
          ...this.generateProductCategories(product),
          id: variant.id,
          item_id: variant.id,
          item_name: name,
          item_variant: variant.title,
          item_brand: product.vendor,
          product_id: product.id,
          currency: product.currency,
          price: variant.price,
        })),
      },
    })

    if (typeof window.kleep !== 'undefined') {
      window.kleep.track('product_viewed', {
        productId: product.id.toString(),
      })
    } else {
      // workaround for Kleep widget not being loaded on first page load
      document.getElementById('kleepWidget')?.addEventListener('load', () => {
        window.kleep.track('product_viewed', {
          productId: product.id.toString(),
        })
      })
    }
  }

  selectVariant(
    name: string,
    product: Product,
    variant: IVariant,
    currency: CURRENCY
  ) {
    this.count += 0.2
    this.clearEcommerce()
    this.event('select_variant', {
      event_category: 'ecommerce',
      event_label: "Sélection d'une variante",
      ecommerce: {
        currency,
        value: variant.price,
        product_id: product.id,
        product_name: name,
        product_category: product.productType,
        items: [
          {
            ...this.generateProductCategories(product),
            id: variant.id,
            item_id: variant.id,
            item_name: name,
            item_variant: variant.title,
            item_brand: product.vendor,
            product_id: product.id,
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    })
  }

  async addToCart(
    name: string,
    product: Product,
    variant: IVariant,
    currency: CURRENCY,
    cart: Cart
  ) {
    this.clearEcommerce()

    const userDatas = await this.getUserData()

    const options: any = {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: variant.price,
        cart_total: cart.cost.subtotalAmount.amount,
        product_name: name,

        // Pinterest
        product_id: product.id,
        product_category: product.productType,
        product_tags: [product.productType, ...product.tags],
        items: [
          {
            ...this.generateProductCategories(product),
            id: variant.id,
            item_id: variant.id,
            item_name: name,
            item_variant: variant.title,
            item_color: variant.opt.color?.label ?? '',
            item_size: variant.opt.size?.label ?? '',
            item_brand: product.vendor,
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    }

    if (this.ctx.route.query.upsell) {
      options.ecommerce.from = 'upsell'
    }

    this.count += 1
    this.event('add_to_cart', options)

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.trackEvent('add_to_cart', {
          label: name,
          tags: [product.productType, ...product.tags],
          attributes: {
            id: product.id,
          },
        })
      })
    }

    if (typeof window.kleep !== 'undefined') {
      window.kleep.track('product_added_to_cart', {
        productId: product.id.toString(),
        variantId: variant.id.toString(),
        cart: cart.lines.edges.map((line) => ({
          productId: ShopifyBase64.getId(
            line.node.merchandise.product.id
          ).toString(),
          variantId: ShopifyBase64.getId(line.node.merchandise.id).toString(),
          sku: line.node.merchandise.sku,
          size: line.node.merchandise.selectedOptions.find(
            (o) => o.name === 'Size'
          )?.value,
          quantity: line.node.quantity,
          price: {
            amount: line.node.cost.amountPerQuantity.amount.toString(),
            currencyCode: line.node.cost.amountPerQuantity.currencyCode,
          },
        })),
      })
    }

    // AB Tasty
    if (ENV_ABTASTY) {
      abtastyService.track({
        cid: ENV_ABTASTY,
        dl: window.location.href,
        pt: document.title,
        ea: 'add_to_cart',
        ec: 'Action Tracking',
        el: `item_name='${name}';item_color='${
          variant.opt.color?.label ?? ''
        }';item_size='${variant.opt.size?.label ?? ''}';item_variant_id='${
          variant.id
        }';item_sku='${variant.sku}';item_ean='${
          variant.barcode
        }';item_vendor='${product.vendor}';item_id='${
          product.id
        }';item_price='${variant.price}'`,
        t: 'EVENT',
        vid: this.ctx.store.state.abtest.visitorId,
        ds: 'APP',
      })
    }
  }

  addToCartAbTestColor(productUid: string, color: string) {
    if (ENV_ABTASTY) {
      abtastyService.track({
        cid: ENV_ABTASTY,
        dl: window.location.href,
        pt: document.title,
        ea: `add_to_cart-${productUid}-${color}`,
        ec: 'Action Tracking',
        el: 'Ajout au panier ciblé',
        t: 'EVENT',
        vid: this.ctx.store.state.abtest.visitorId,
        ds: 'APP',
      })
    }
  }

  async addSuitToCart(
    name: string,
    product: Product,
    jacket: IVariant | undefined,
    pant: IVariant | undefined,
    currency: CURRENCY,
    cart: Cart
  ) {
    const items = []
    let price = 0

    if (jacket) {
      items.push({
        ...this.generateProductCategories(product),
        id: jacket.id,
        item_id: jacket.id,
        item_name: name,
        item_brand: product.vendor,
        item_variant: `${jacket.opt.group.label} / ${jacket.opt.color.label} / ${jacket.opt.size.label}`,
        currency,
        price: jacket.price,
        quantity: 1,
      })

      price += jacket.price
    }

    if (pant) {
      items.push({
        ...this.generateProductCategories(product),
        id: pant.id,
        item_id: pant.id,
        item_name: name,
        item_brand: product.vendor,
        item_variant: `${pant.opt.group.label} / ${pant.opt.color.label} / ${pant.opt.size.label}`,
        currency,
        price: pant.price,
        quantity: 1,
      })

      price += pant.price
    }

    this.count += 1
    this.clearEcommerce()

    const userDatas = await this.getUserData()

    this.event('add_to_cart', {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: price,
        cart_total: cart.cost.subtotalAmount.amount,
        product_name: name,
        items,
      },
    })
    if (typeof window.kleep !== 'undefined') {
      for (const item of items) {
        window.kleep.track('product_added_to_cart', {
          productId: product.id.toString(),
          variantId: item.id.toString(),
          cart: cart.lines.edges.map((line) => ({
            productId: ShopifyBase64.getId(
              line.node.merchandise.product.id
            ).toString(),
            variantId: ShopifyBase64.getId(line.node.merchandise.id).toString(),
            sku: line.node.merchandise.sku,
            size: line.node.merchandise.selectedOptions.find(
              (o) => o.name === 'Size'
            )?.value,
            quantity: line.node.quantity,
            price: {
              amount: line.node.cost.amountPerQuantity.amount.toString(),
              currencyCode: line.node.cost.amountPerQuantity.currencyCode,
            },
          })),
        })
      }
    }
  }

  addUpSellToCart(name: string, variant: any, currency: CURRENCY) {
    this.clearEcommerce()
    this.event('add_to_cart', {
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: variant.price,
        items: [
          {
            id: variant.id,
            item_id: variant.id,
            item_variant: variant.title,
            item_name: name,
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    })

    if (window.clarity) {
      window.clarity('event', 'add_upsell_to_cart')
    }
  }

  removeFromCart(cartItem: CartLine, currency: CURRENCY) {
    this.clearEcommerce()
    this.event('remove_from_cart', {
      event_category: 'ecommerce',
      event_label: 'Suppression du panier',
      ecommerce: {
        currency,
        value: cartItem.cost.amountPerQuantity.amount,
        items: [
          {
            id: ShopifyBase64.getId(cartItem.merchandise.id),
            item_id: ShopifyBase64.getId(cartItem.merchandise.id),
            item_name: cartItem.merchandise.product.title,
            item_variant: cartItem.merchandise.title,
            item_brand: cartItem.merchandise.product.vendor,
            currency,
            price: cartItem.cost.amountPerQuantity.amount,
            quantity: 1,
          },
        ],
      },
    })
  }

  beginCheckout(cart: Cart) {
    const items: any[] = []

    cart.lines.edges.forEach((e) => {
      const itemCategories: any = {}

      // LEGACY
      /* item.productTags.forEach((t, index) => {
        itemCategories[`item_category${index + 2}`] = t
      }) */

      items.push({
        id: ShopifyBase64.getId(e.node.merchandise.id),
        item_id: ShopifyBase64.getId(e.node.merchandise.id),
        item_name: e.node.merchandise.product.title,
        item_brand: e.node.merchandise.product.vendor,
        item_variant: e.node.merchandise.title,
        item_category: e.node.merchandise.product.productType,
        ...itemCategories,
        currency: cart.cost.subtotalAmount.currencyCode,
        price: e.node.cost.amountPerQuantity.amount,
        quantity: e.node.quantity,

        // Pinterest
        product_id: ShopifyBase64.getId(e.node.merchandise.product.id),
        product_category: e.node.merchandise.product.productType,
      })
    })

    this.clearEcommerce()
    this.event('begin_checkout', {
      event_category: 'ecommerce',
      event_label: 'Initialisation tunnel de paiement',
      ecommerce: {
        currency: cart.cost.subtotalAmount.currencyCode,
        value: cart.cost.subtotalAmount.amount,
        num_items: cart.lines.edges.length,
        items,
      },
    })
  }

  scroll(percent: string) {
    this.event('scroll', {
      event_category: 'navigation',
      event_label: 'Scroll',
      value: percent,
    })
  }

  colorChange(color: string, product: string) {
    this.count += 0.2
    this.event('color_change', {
      event_category: 'navigation',
      event_label: 'Changement de couleur',
      color,
      product,
    })
  }

  hasSeenExperiment(experiment: string) {
    this.event('has_seen_experiment', {
      event_category: 'ab_testing',
      event_label: 'A vue un test A/B',
      experiment,
    })
  }

  swipe() {
    this.count += 0.2
    this.event('swipe', {
      event_category: 'navigation',
      event_label: "Swipe sur un slider d'image",
    })
  }

  sliderChange(direction: string) {
    this.count += 0.2
    this.event('slider_nav', {
      event_category: 'navigation',
      event_label: "Navigation sur un slider d'image",
      direction,
    })
  }

  async bisSubscribe(email: string, target: string, origin: ORIGIN) {
    const encodedEmail = await this.digestMessage(email)

    this.event('subscribe', {
      event_category: 'bis',
      event_label: 'Inscription Back In Stock',
      origin,
      target,
      user_data: {
        email: encodedEmail,
      },
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.addTag('bis_subscribe', target)
        })
      })
    }
  }

  bisUnSubscribe(target: string, origin: ORIGIN) {
    this.event('unsubscribe', {
      event_category: 'bis',
      event_label: 'Désinscription Back In Stock',
      origin,
      target,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.removeTag('bis_subscribe', target)
        })
      })
    }
  }

  async login(customer: Customer) {
    const encodedEmail = await this.digestMessage(customer.email)

    this.event('login', {
      event_category: 'user',
      event_label: 'Connexion',
      user_data: {
        customer_id: customer.id,
        email: encodedEmail,
      },
      method: 'Shopify',
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(customer.id)
      })
    }
  }

  password() {
    this.event('set_password', {
      event_category: 'user',
      event_label: 'Mot de passe',
      method: 'Shopify',
    })
  }

  async signUp(customer: Customer) {
    const encodedEmail = await this.digestMessage(customer.email)

    this.event('sign_up', {
      event_category: 'user',
      event_label: 'Inscription',
      user_data: {
        customer_id: customer.id,
        email: encodedEmail,
      },
      method: 'Shopify',
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(customer.id)
      })
    }
  }

  logout() {
    this.event('logout')

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(null)
      })
    }
  }

  click(name: string, options?: object) {
    this.count += 0.5
    this.event(name, {
      event_category: 'navigation',
      event_label: 'Click sur un lien',
      ...options,
    })
  }

  filter(group: string, name: string, options?: object) {
    this.event('filter', {
      event_label: "Mise à jour d'un filtre",
      event_category: 'collection',
      filter_group: group,
      filter_name: name,
      ...options,
    })
  }

  slice(id: string) {
    this.event('slice_seen', {
      id,
    })
  }

  optimizeVariant(v: string) {
    this.event('optimize_variant', { variant: v })
  }

  fitleWidgetEnded() {
    this.event('fitle_widget_ended', {
      event_category: 'fitle',
      event_label: 'Fitle widget ended',
    })
  }

  deployIdTime(duration: number) {
    this.event('deploy_id_time', {
      event_category: 'performance',
      event_label: `Deploy ID time`,
      value: duration,
    })
  }

  localVersionIsOutdated() {
    this.event('local_version_is_outdated', {
      event_category: 'performance',
      event_label: `Local version is outdated - Force refresh`,
    })
  }

  getDeployIdTooLong() {
    this.event('get_deploy_id_too_long', {
      event_category: 'performance',
      event_label: `getDeployId took too long`,
    })
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $track: Tracking
  }
}

const TrackingPlugin: Plugin = (context, inject) => {
  inject('track', new Tracking(context))
}

export default TrackingPlugin
