export const backgroundContentBlock = `
_type == 'backgroundContentBlock' => {
  image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
}
`
