import { MutationTree } from 'vuex'
import { ABTestingState } from './types'
import ABTestCampaigns from '~/entities/ABTestCampaigns'

export const mutations: MutationTree<ABTestingState> = {
  VISITOR_ID(state: ABTestingState, visitorId: string) {
    state.visitorId = visitorId
  },
  CAMPAIGNS(state: ABTestingState, campaigns: ABTestCampaigns) {
    state.campaigns = campaigns
  },
  LOADING(state: ABTestingState, loading: boolean) {
    state.loading = loading
  },
  ERRORS(state: ABTestingState, errors: Error[]) {
    state.errors = errors.map((e) => e.message)
  },
  CLEAR_ERRORS(state: ABTestingState) {
    state.errors = []
  },
}
