export const issuesBlock = `
_type == 'issueBlock' => {
  "title": title[_key == $lang][0].value,
  "up_title": up_title[_key == $lang][0].value,
  issues[] {
    ...,
    "issue": issue[_key == $lang][0].value,
  }
}
`
