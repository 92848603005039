export const imageFullBlock = `
_type == 'imageFullBlock' => {
  image {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    image_mobile {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  "caption": caption[_key == $lang][0].value,
  background_image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
}
`
