export const simpleContentBlock = `
_type == 'simpleContentBlock' => {
  image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
}
`
