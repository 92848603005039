import { MediaBunny } from '@/types/sanity.types'
import { GALLERY_IMAGE_FORMAT, PRODUCT_IMAGE_FORMAT } from '~/const/image'

export type ImageWithCrop = {
  image: MediaBunny
  crop: {
    width: number // Ratio 0-1 vis a vis de la largeur de l'image max
    height: number // Ratio 0-1 vis a vis de la hauteur de l'image max
    x: number // Position centrale x du crop
    y: number // Position centrale y du crop
  }
}

function fromRatioToPixels(ratio: number, total: number) {
  return Math.round(ratio * total)
}

/**
 *
 * @param image Objet Image Bunny de Sanity
 * @param dimensions Dimension cible de l'image (width, height)
 * @returns l'url de l'image avec les paramètres de crop et de dimension
 */
export function getSanityBunnyImageLink(
  image: ImageWithCrop,
  dimensions?: {
    width?: number
    height?: number
  }
): string {
  if (image && image.image.path) {
    const url = new URL(`${process.env.BUNNY_CDN_HOSTNAME}${image.image.path}`)

    if (
      image.image.dimensions &&
      image.image.dimensions.width &&
      image.image.dimensions.height
    ) {
      let maxWidth = image.image.dimensions.width
      let maxHeight = image.image.dimensions.height

      if (image.crop) {
        maxWidth = fromRatioToPixels(
          image.crop.width,
          image.image.dimensions.width || 0
        )
        maxHeight = fromRatioToPixels(
          image.crop.height,
          image.image.dimensions.height || 0
        )

        let w = maxWidth
        let h = maxHeight

        if (dimensions?.width && dimensions.height) {
          const ratio = dimensions.width / dimensions.height
          const targetHeight = Math.round(maxWidth / ratio)

          if (targetHeight > maxHeight) {
            w = Math.round(maxHeight * ratio)
            h = maxHeight
          } else {
            w = maxWidth
            h = targetHeight
          }
        }

        url.searchParams.append(
          'focus_crop',
          `${w},${h},${image.crop.x},${image.crop.y}`
        )
      } else if (dimensions?.width && dimensions.height) {
        const ratio = dimensions.width / dimensions.height
        const targetHeight = Math.round(maxWidth / ratio)

        if (targetHeight > maxHeight) {
          url.searchParams.append(
            'crop',
            `${Math.round(maxHeight * ratio)},${maxHeight}`
          )
        } else {
          url.searchParams.append('crop', `${maxWidth},${targetHeight}`)
        }
      }
    }

    if (dimensions?.width) {
      url.searchParams.append('width', dimensions.width.toString())
    }

    if (dimensions?.height) {
      url.searchParams.append('height', dimensions.height.toString())
    }

    return url.toString()
  }

  return ''
}

enum RENDER_AS {
  FULL_IMAGES = 'full-images',
  ADAPT_TO_SCREEN = 'adapt-to-screen',
}

export function getProductCardImageListMobile(
  image: ImageWithCrop,
  renderAs?: RENDER_AS,
  isFirst?: boolean
) {
  return {
    image: {
      url: getSanityBunnyImageLink(
        image,
        renderAs === RENDER_AS.FULL_IMAGES
          ? GALLERY_IMAGE_FORMAT.PORTRAIT
          : PRODUCT_IMAGE_FORMAT.LIST
      ),
      dimensions:
        renderAs === RENDER_AS.FULL_IMAGES
          ? GALLERY_IMAGE_FORMAT.PORTRAIT
          : PRODUCT_IMAGE_FORMAT.LIST,
    },
    widths: [380, 570, 760],
    media: {
      type: 'max',
      value: 769,
    },
    isFirst,
  }
}

export function getProductCardImageListDesktop(
  image: ImageWithCrop,
  type: string,
  renderAs?: RENDER_AS,
  isFirst?: boolean
) {
  if (type === 'slider') {
    return {
      image: {
        url: getSanityBunnyImageLink(image, PRODUCT_IMAGE_FORMAT.LIST),
        dimensions: PRODUCT_IMAGE_FORMAT.LIST,
      },
      media: {
        type: 'min',
        value: 770,
      },
      widths: [380, 570, 760],
      sizes: '380px',
      isFirst,
    }
  } else if (type === 'one-per-line') {
    return {
      image: {
        url: getSanityBunnyImageLink(
          image,
          renderAs === RENDER_AS.FULL_IMAGES
            ? GALLERY_IMAGE_FORMAT.LANDSCAPE
            : PRODUCT_IMAGE_FORMAT.HIGHLIGHT
        ),
        dimensions: PRODUCT_IMAGE_FORMAT.HIGHLIGHT,
      },
      media: {
        type: 'min',
        value: 770,
      },
      widths: [1440, 2160, 2880],
      sizes: '100vw',
      isFirst,
    }
  } else if (type === 'two-per-line') {
    return {
      image: {
        url: getSanityBunnyImageLink(
          image,
          renderAs === RENDER_AS.FULL_IMAGES
            ? GALLERY_IMAGE_FORMAT.HALF
            : PRODUCT_IMAGE_FORMAT.HALF
        ),
        dimensions:
          renderAs === RENDER_AS.FULL_IMAGES
            ? GALLERY_IMAGE_FORMAT.HALF
            : PRODUCT_IMAGE_FORMAT.HALF,
      },
      media: {
        type: 'min',
        value: 770,
      },
      widths: [720, 1080, 1440],
      sizes: '50vw',
      isFirst,
    }
  }

  return {
    image: {
      url: getSanityBunnyImageLink(
        image,
        renderAs === RENDER_AS.FULL_IMAGES
          ? GALLERY_IMAGE_FORMAT.PORTRAIT
          : PRODUCT_IMAGE_FORMAT.LIST
      ),
      dimensions:
        renderAs === RENDER_AS.FULL_IMAGES
          ? GALLERY_IMAGE_FORMAT.PORTRAIT
          : PRODUCT_IMAGE_FORMAT.LIST,
    },
    media: {
      type: 'min',
      value: 770,
    },
    widths: [480, 720, 960],
    sizes: '33vw',
    isFirst,
  }
}

export function getProductCardImageList(image: ImageWithCrop, type: string) {
  const imgs = []

  // MOBILE
  imgs.push(getProductCardImageListMobile(image))

  // DESKTOP
  imgs.push(getProductCardImageListDesktop(image, type))

  return imgs
}
