import { productCardGroq } from '../../utils/product'

export const galleryOfProductsAndLinksBlock = `
_type == 'galleryOfProductsAndLinksBlock' => {
  lines[] {
    ...,
    _type == 'aLineOfOneProductAndHisColors' => {
      product->{
        _id,
        _type,
        "uid": uid[_key == $lang][0].value,
        "title": title[_key == $lang][0].value,
        type,
        status,
        context,
        type,
        "punchline": punchline[_key == $lang][0].value,
        dates {
          start,
          end,
          teasing,
        },
        current_batch_date_end,
        next_delivery_date,
        shopify_product->{
          _id,
          id,
          options[] {
            _key,
            position,
            values [] {
              _key,
              "name": value[_key == $lang][0].value,
            }
          },
          "colorOptions": options[_key == 'color'].values[] {
            _key,
            "name": value[_key == $lang][0].value,
            priceRange {
              minVariantPrice,
              maxVariantPrice,
            },
            compareAtPriceRange {
              minVariantPrice,
              maxVariantPrice,
            },
          }
        },
        colors[] {
          _key,
          name,
          color_status,
          image {
            crop,
            image-> {
              _type,
              _id,
              path,
              dimensions
            }
          },
          thumbnail {
            crop,
            image-> {
              _type,
              _id,
              path,
              dimensions
            }
          },
        },
        has_third_option,
        third_options {
          list,
          layout,
        },
        has_linked_product,
        linked_products[] {
          product->{
            _id,
            _type,
            "uid": uid[_key == $lang][0].value,
            context,
            shopify_product->{
              _id,
              id,
              options[] {
                _key,
                position,
                values [] {
                  _key,
                  "name": value[_key == $lang][0].value,
                }
              },
              "colorOptions": options[_key == 'color'].values[] {
                _key,
                "name": value[_key == $lang][0].value,
                priceRange {
                  minVariantPrice,
                  maxVariantPrice,
                },
                compareAtPriceRange {
                  minVariantPrice,
                  maxVariantPrice,
                },
              }
            },
            colors[] {
              _key,
              name,
              color_status,
              image {
                crop,
                image-> {
                  _type,
                  _id,
                  path,
                  dimensions
                }
              },
              thumbnail {
                crop,
                image-> {
                  _type,
                  _id,
                  path,
                  dimensions
                }
              },
            }
          }
        },
        image {
          image {
            crop,
            image-> {
              _type,
              _id,
              path,
              dimensions
            }
          },
          top_of_image_is_light,
        },
        image_eshop {
          image {
            crop,
            image-> {
              _type,
              _id,
              path,
              dimensions
            }
          },
          top_of_image_is_light,
        },
      },
      colors,
    },
    _type == 'lineOfLinks' => {
      links[] {
        _key,
        _type,
        ...,
        _type in ['linkToProduct', 'linkToProductColor'] => {
          product->{
            ${productCardGroq}
          },
        },
        _type == 'linkToContent' => {
          link{
            ...,
            internalLink->{ _type, "uid": uid[_key == $lang][0].value, "title": title[_key == $lang][0].value, context, },
          },
          "title": title[_key == $lang][0].value,
          "hook": hook[_key == $lang][0].value,
          "btn_label": btn_label[_key == $lang][0].value,
        },
        image {
          crop,
          image-> {
            _type,
            _id,
            path,
            dimensions
          }
        },
      }
    }
  },
}
`
