export const productCardGroq = `
  _id,
  _type,
  context,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  type,
  status,
  is_suit,
  shopify_product->{
    _id,
    id,
    priceRange {
      minVariantPrice,
      maxVariantPrice,
    },
    compareAtPriceRange {
      minVariantPrice,
      maxVariantPrice,
    },
    options,
    "colorOptions": options[_key == 'color'].values[] {
      _key,
      "name": value[_key == $lang][0].value
    }
  },
  dates {
    start,
    end,
    teasing,
  },
  image {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    top_of_image_is_light,
  },
  teasing_link->{
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
  },
  colors[] {
    _key,
    color_status,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    thumbnail {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  third_options {
    list,
    layout,
  },
  marketing_arguments_type,
  "marketing_message": marketing_message[_key == $lang][0].value,
  marketing_arguments_render_as,
  "button_label": button_label[_key == $lang][0].value,
`

export const productPageGroq = `
  _id,
  _type,
  _createdAt,
  _updatedAt,
  context,
  "uid": uid[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  "title": title[_key == $lang][0].value,
  "bullets": bullets[_key == $lang][0].value,
  is_suit,
  shopify_product->{
    _id,
    id,
    priceRange {
      minVariantPrice,
      maxVariantPrice,
    },
    compareAtPiceRange {
      minVariantPrice,
      maxVariantPrice,
    },
    productType,
    options,
    "colorOptions": options[_key == 'color'].values[] {
      _key,
      "name": value[_key == $lang][0].value
    }
  },
  stock_initial,
  has_third_option,
  "third_option_label": third_option_label[_key == $lang][0].value,
  third_options {
    list[] {
      _key,
      name,
      "desc": desc[_key == $lang][0].value,
      image {
        crop,
        image-> {
          _type,
          _id,
          path,
          dimensions
        }
      },
    },
    layout,
  },
  version,
  status,
  type,
  dates {
    start,
    end,
    teaasing,
  },
  colors[] {
    _key,
    name,
    color_status,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    thumbnail {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    slider-> {
      _id,
      title,
      elements[] {
        _key,
        _type,
        _type == 'slider_image' => {
          image {
            crop,
            image-> {
              _type,
              _id,
              path,
              dimensions
            }
          }
        },
        _type == 'slider_video' => {
          video {
            id,
            folder,
          }
        },
      },
      gallery,
      gallery_mobile,
    }
  },
  current_batch_date_end,
  next_delivery_date,
  rating_average,
  rating_number,
  stock_initial,
  product_as_preco_or_stock->{
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    status,
    shopify_product->{
      _id,
      id,
    }
  },
  has_linked_product,
  linked_products[] {
    product->{
      _id,
      "uid": uid[_key == $lang][0].value,
    },
    "label": label[_key == $lang][0].value,
    show_option,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    }
  },
  image {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    }
  },
  highlight_size_guide,
  size_guide,
  care_guide->{
    _id,
    "uid": uid[_key == $lang][0].value,
  },
  delivery_and_returns
`
