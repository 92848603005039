export const baniereBlock = `
_type == 'baniereBlock' => {
  image {
    ...,
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  },
  "text": text[_key == $lang][0].value,
}
`
