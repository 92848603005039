import { portableTextWithInternalLinks } from '../utils/portableText'

export const galleryBlock = `
_type == 'galleryBlock' => {
  children[]{
    ...,
    _type == 'child_image' => {
      image {
        image {
          crop,
          image-> {
            _type,
            _id,
            path,
            dimensions
          }
        },
        image_mobile {
          crop,
          image-> {
            _type,
            _id,
            path,
            dimensions
          }
        },
      },
    },
    _type == 'child_edito' => {
      "text": text[_key == $lang][0].value[] {
        ${portableTextWithInternalLinks}
      },
    },
  }
}
`
