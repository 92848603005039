import { sanityLinkResolver } from './utils/linkResolver'
import Route from '@/types/prismic/routes'

import linkResolver from '@/plugins/prismic/link-resolver'
import { CONTEXTS } from '@/const'
import { SanityInternalLink } from '@/types/sanity'

class LinkResolverService {
  getPathFromSanityLink(internalLink: SanityInternalLink): string {
    return sanityLinkResolver(this.prepareUid(internalLink))
  }

  getPathFromLink(r: Route) {
    return linkResolver(this.preparePrismicUid(r))
  }

  removeContextFromUid(uid: string): string {
    for (const context of CONTEXTS) {
      if (uid.slice(-2) === `-${context}`) {
        return uid.slice(0, -2)
      }
    }

    return uid
  }

  uidHasContext(uid: string): boolean {
    let has = false

    CONTEXTS.forEach((c) => {
      if (uid.slice(-2) === `-${c}`) {
        has = true
      }
    })

    return has
  }

  getContextFromUid(uid: string): string {
    let context = 'h'
    for (const c of CONTEXTS) {
      if (uid.slice(-2) === `-${c}`) {
        context = c
      }
    }
    return context
  }

  preparePrismicUid(route: Route): Route {
    if (
      route.type === 'product' ||
      route.type === 'collection' ||
      route.type === 'blog'
    ) {
      return {
        ...route,
        uid: this.removeContextFromUid(route.uid),
      }
    }

    return route
  }

  prepareUid(internalLink: SanityInternalLink): SanityInternalLink {
    if (internalLink._type === 'product') {
      return {
        ...internalLink,
        uid: this.removeContextFromUid(internalLink.uid),
      }
    }

    return internalLink
  }
}

export default new LinkResolverService()
