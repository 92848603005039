import groq from 'groq'
import { blocks } from '../blocks'
import { portableTextWithInternalLinks } from '../utils/portableText'

export const getBlogGroq = groq`
  *[_type == 'blog' && $uid in uid[].value && $lang in uid[]._key] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "title": title[_key == $lang][0].value,
    "alternate_languages": uid[_key != $lang],
    list_type,
  }
`

export const getBlogPostsGroq = groq`
  *[_type == $type 
    && context == $context
    && (
      published_at < $lastPublishedAt
      || (publishedAt == $lastPublishedAt && _id > $lastId)
    )
    && $lang in uid[]._key
  ] | order(published_at desc) [0...30] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "title": title[_key == $lang][0].value,
    "hook": hook[_key == $lang][0].value,
    published_at,
    with_image_header,
    image_header {
      image {
        crop,
        image-> {
          _type,
          _id,
          path,
          dimensions
        }
      },
      darken_image,
      top_of_image_is_light
    },
    image_list {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  }
`

export const getBlogPostsWithSearchGroq = groq`
  *[_type == $type 
    && context == $context 
    && (
      published_at < $lastPublishedAt
      || (publishedAt == $lastPublishedAt && _id > $lastId)
    )
    && $lang in uid[]._key
    && (
      title[_key == $lang][0].value match $search
      || hook[_key == $lang][0].value match $search
      || context[_key == $lang][0].value match $search
    )
  ] | order(published_at desc) [0...30] {
    _id,
    _type,
    "uid": uid[_key == $lang][0].value,
    "title": title[_key == $lang][0].value,
    "hook": hook[_key == $lang][0].value,
    published_at,
    with_image_header,
    image_header {
      image {
        crop,
        image-> {
          _type,
          _id,
          path,
          dimensions
        }
      },
      darken_image,
      top_of_image_is_light
    },
    image_list {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
  }
`

const articleDocument = `
  _id,
  _type,
  _createdAt,
  _updatedAt,
  context,
  "uid": uid[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  "title": title[_key == $lang][0].value,
  "context_info": context_info[_key == $lang][0].value,
  "hook": hook[_key == $lang][0].value[] {
    ${portableTextWithInternalLinks}
  },
  with_image_header,
  image_header {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    darken_image,
    top_of_image_is_light
  },
  show_publish_date,
  published_at,
  ${blocks}
`
export const getArticlesGroq = groq`
  *[_type == "article" && $lang in uid[]._key] {
    ${articleDocument}
  }
`

export const getArticlesLimitGroq = groq`
  *[_type == "article" && $lang in uid[]._key] | order(published_at desc) [0...$limit] {
    ${articleDocument}
  }
`

export const getArticleGroq = groq`
  *[_type == "article" 
    && context == $context
    && $uid in uid[].value
    && $lang in uid[]._key
  ] {
    ${articleDocument}
  }
`

const careGuideDocument = `
  _id,
  _type,
  _createdAt,
  _updatedAt,
  context,
  "uid": uid[_key == $lang][0].value,
  "alternate_languages": uid[_key != $lang],
  "title": title[_key == $lang][0].value,
  with_image_header,
  image_header {
    image {
      crop,
      image-> {
        _type,
        _id,
        path,
        dimensions
      }
    },
    darken_image,
    top_of_image_is_light
  },
  image_list {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
  ${blocks}
  `
export const getCareGuidesGroq = groq`
  *[_type == "care_guide" && $lang in uid[]._key] {
    ${careGuideDocument}
  }
`

export const getCareGuidesLimitGroq = groq`
  *[_type == "care_guide" && $lang in uid[]._key] | order(published_at desc) [0...$limit] {
    ${careGuideDocument}
  }
`

export const getCareGuideGroq = groq`
  *[_type == "care_guide" 
    && context == $context
    && $uid in uid[].value 
    && $lang in uid[]._key
  ] {
    ${careGuideDocument}
  }
`
