import groq from 'groq'
import { blocks } from '../blocks'

export const getAppConfigGroq = groq`*[_id == "app_config"] {
  _id,
  _type,
  version,
  configurations,
  menu,
  default_gallery,
}`

export const getByIdGroq = groq`*[_id == $id] {
  _id,
  _type,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  ${blocks}
}`

export const getPortalGroq = groq`*[_id == "portal"] {
  _id,
  _type,
  list[] {
    _key,
    "title": title[_key == $lang][0].value,
    image {
      crop,
      image-> {
          _type,
          _id,
          path,
          dimensions
      }
    },
    link {
      internalLink-> {
        _type,
        "uid": uid[_key == $lang][0].value,
        context,
      }
    },
    color,
  }
}`

export const getSettingsGroq = groq`*[_id == "settings"]`

export const getContextsGroq = groq`*[_type == "context"] {
  label,
  "uid": uid.current,
}`

export const getLocalesGroq = groq`*[_type == "locale"] {
  "code": code.current,
  is_default,
}`

export const getBiblioGroq = groq`*[_id == $ref] {
  _id,
  _type,
  "uid": uid[_key == "fr"][0].value,
  "title": title[_key == "fr"][0].value,
  "explication": explication[_key == "fr"][0].value,
}`
