export const sizeGuideImagesBlock = `
_type == 'sizeGuideImagesBlock' => {
  list[] {
    ...,
    image {
      crop,
      image-> { ..., } 
    },
  }
}
`
